import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content", "error"]

  connect() {
    if (this.tabTargets.length > 0) {
      this.showTabWithErrors() || this.activate(this.tabTargets[0])
    }
  }

  switch(event) {
    event.preventDefault()
    this.activate(event.currentTarget)
  }

  showTabWithErrors() {
    const tabWithError = this.contentTargets.find(content => content.querySelector('.field_with_errors'))
    if (tabWithError) {
      const tabId = tabWithError.id
      const tab = this.tabTargets.find(t => t.dataset.tabContent === tabId)
      if (tab) {
        this.activate(tab)
        return true
      }
    }
    return false
  }

  activate(tab) {
    this.tabTargets.forEach(t => {
      t.classList.remove('active', 'btn-white')
      t.classList.add('btn-light')
    })

    this.contentTargets.forEach(c => c.classList.add('d-none'))

    tab.classList.add('active', 'btn-white')
    tab.classList.remove('btn-light')
    
    const contentId = tab.dataset.tabContent
    const content = this.contentTargets.find(c => c.id === contentId)
    if (content) {
      content.classList.remove('d-none')
    }
  }

  submitForm(event) {
    return true
  }
}