import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.formatUrl()
  }

  formatUrl() {
    const input = this.inputTarget
    input.addEventListener('blur', () => {
      let url = input.value.trim()
      if (url && !url.match(/^https?:\/\//)) {
        input.value = `https://${url}`
      }
    })
  }
}