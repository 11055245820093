import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["description", "select"]
  static values = { decoratorName: String }

  connect() {
    this.updateDescription(this.selectTarget.value)
  }

  updateDescription(value) {
    const text = value === 'single_invoice' 
      ? "Single invoice means the decorator purchases blanks for Brand Sauce."
      : `Double invoice means Brand Sauce is responsible for purchasing and shipping the blanks to ${this.decoratorNameValue}.`
    this.descriptionTarget.textContent = text
  }

  change(event) {
    this.updateDescription(event.target.value)
  }
}