import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log("Chart interval controller connected")
  }

  submit(event) {
    this.formTarget.requestSubmit()
  }
}